@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    /* background-image: url('./assets/background.jpg'); */
    background-color: lightgrey;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.default-hover {
    color: black;
    cursor: pointer;
    font-family: 'Ibarra Real Nova', serif;
    font-size: clamp(1rem, 2vw, 1.2rem);
    font-variant: small-caps;
    font-size: 28px;
    letter-spacing: 0.4vmin;
    transition: opacity 400ms ease, color 0.2s;
}

.default-hover:hover {
    text-decoration: underline;
    color: #1bd6e4;
}

p {
    border-bottom-width: 2px;
}

h1 {
    border-bottom-width: 2px;
    border-top-width: 2px;
    border-color: black;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.hide-scrollbar {
    /* IE and Edge */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;
}